/* eslint-disable react-hooks/exhaustive-deps */
import { LastThingEvent, PersonThingRange, Thing, ThingType } from '@eagle/core-data-types';
import { Location } from '@eagle/data-function-types';
import { CacheDataTypes, captureException, DEFAULT_THING_MAP_POPUP, ErrorMessage, evaluate, LastThingEventsByFeature, MapPanes, MapPopup, Undefinable, useAuthenticated, useCustomRoutes, useFetchAllCache, useFetchOneCache, useLastThingState, useListSearch, useMapContext, usePromise, useUiTemplate, validateLocationType } from '@eagle/react-common';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LocationState } from './thing-map-navigator';

export const ThingPopup: FC = () => {
  const { thingId } = useParams();
  const { axios } = useAuthenticated();
  const { things: customRoutesThings } = useCustomRoutes();
  const { enqueueSnackbar } = useSnackbar();
  const { setSavedSearchPosition, setMapDimming } = useMapContext();
  const { template, loaded: templateLoaded } = useUiTemplate('thing-map-popup', DEFAULT_THING_MAP_POPUP);
  const [entityId, setEntityId] = useState<string>();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [locationData, setLocationData] = useState<Undefinable<Location>>();
  const [lastThingEvent, setLastThingEvent] = useState<LastThingEvent>();
  const [lastThingEvents, setLastThingEvents] = useState<LastThingEventsByFeature>();
  const location = useLocation();
  const map = useMap();
  const navigate = useNavigate();
  const state = location.state as LocationState;
  const shouldFly = state?.shouldFly ?? true;
  const thingCache = useFetchOneCache(CacheDataTypes.THING);
  const thingTypeCache = useFetchAllCache(CacheDataTypes.THING_TYPE);
  const { setText } = useListSearch();

  const [fetchState] = useLastThingState();

  useEffect(() => {
    if (!thingId) return;

    setLastThingEvents(undefined);
    setLastThingEvent(undefined);

    fetchState(thingId).then((events) => {
      setLastThingEvents(events?.byFeature);

      const locationUpdate = events?.byFeature.tracking?.['location-update'];
      if (!locationUpdate) {
        setLocationData(undefined);
        return;
      }

      setLastThingEvent(events.latest);
      setLocationData(validateLocationType(() => locationUpdate.data.location));
    }).catch(captureException);
  }, [fetchState, thingId]);

  const openPopup = (): void => {
    setMapDimming('things', true);
    setIsPopupOpen(true);
    setEntityId(thingId);
  };

  const closePopup = (): void => {
    setMapDimming('things', false);
    setIsPopupOpen(false);
    setEntityId(undefined);
  };

  const handleCloseButtonClick = (): void => {
    closePopup();
    setText('');
    if (!state?.previousLocations) {
      setSavedSearchPosition(null);
      navigate(`/map/${customRoutesThings}`, {
        state: {
          shouldFly: false,
        },
      });
      return;
    }
    navigate(-1);
  };

  const handleEscapeKeyDown = (e: KeyboardEvent): void => {
    if (!thingId || e.key !== 'Escape') return;
    handleCloseButtonClick();
  };

  const handleMapClick = (): void => {
    if (!thingId) return;
    closePopup();
    setSavedSearchPosition(null);
    navigate(`/map/${customRoutesThings}`, {
      state: {
        shouldFly: false,
      },
    });
  };

  const [thing, thingError, thingStatus] = usePromise<Undefinable<Thing>>(
    () => {
      if (!entityId) return Promise.resolve(undefined);
      return thingCache.one(entityId);
    },
    [entityId]
  );

  const [thingPersons, thingPersonsError, thingPersonsStatus] = usePromise<PersonThingRange[]>(
    async () => {
      if (!thing) return [];
      const result = await axios.get<PersonThingRange[]>(`/api/v1/person-thing/thing/${thing._id}/current`);
      return result.data;
    },
    [thing],
  );

  const [thingType, thingTypeError, thingTypeStatus] = usePromise<Undefinable<ThingType>>(
    () => {
      if (!thing) return Promise.resolve(undefined);
      return thingTypeCache.one(thing?.thingTypeId);
    },
    [thing]
  );

  const statuses = [thingStatus, thingTypeStatus, thingPersonsStatus];

  useEffect(() => {
    const errorCheck = thingError || thingTypeError || thingPersonsError;
    if (!errorCheck) return;
    navigate(`/map/${customRoutesThings}`, {
      state: {
        shouldFly: false,
      },
    });
    enqueueSnackbar(<ErrorMessage error={errorCheck} />, { variant: 'error' });
  }, [thingError, thingTypeError, thingPersonsError]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKeyDown);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyDown);
    };
  }, [thingId]);

  useEffect(() => {
    if (!isPopupOpen) return;
    map.addEventListener('click', handleMapClick);

    return () => {
      map.removeEventListener('click', handleMapClick);
    };
  }, [isPopupOpen, thingId]);

  useEffect(() => {
    if (!thingId) return;
    map.once('markerLoaded', openPopup);
    if (shouldFly) return;
    openPopup();
  }, [thingId, shouldFly]);

  if (statuses.includes('pending')) return <></>;
  if (thingId !== entityId || !thing || !thingPersons || !templateLoaded) return <></>;

  return (
    <MapPopup
      handleCloseButtonClick={handleCloseButtonClick}
      isPopupOpen={isPopupOpen}
      locationData={locationData}
      pane={MapPanes.POPUP_PANE}
    >
      <>{evaluate(template, { ...thing, lastThingEvent, lastEvents: lastThingEvents, thingType, thingPersons, location: locationData })}</>
    </MapPopup>
  );
};
